<template>
<section class="solution">
   <div class="banner">
      <img :src="$t('locale')=='zh'?banner:banner_en" alt="" />
   </div>
   <section class="main_contain">
      <figure class="fg1">
         <p v-html="$t('PC.Solution.nbxgrj')"></p>
      </figure>
      <figure class="fg2">
         <div>
            <article>
               <figure>
                  <figcaption>
                     <p>{{ $t("PC.Solution.dmlqae") }}</p>
                     <i></i>
                  </figcaption>
                  <span>{{ $t("PC.Solution.lv198h") }}</span>
               </figure>
            </article>
            <img src="../../assets/zh/images/fangan_img1.jpg" alt="" />
         </div>
         <div>
            <article>
               <figure>
                  <figcaption>
                     <p>{{ $t("PC.Solution.1uf1y4") }}</p>
                     <i></i>
                  </figcaption>
                  <span>{{ $t("PC.Solution.cz13x4") }}</span>
               </figure>
            </article>
            <img src="../../assets/zh/images/fangan_img2.jpg" alt="" />
         </div>
         <div>
            <article>
               <figure>
                  <figcaption>
                     <p>{{ $t("PC.Solution.htlg8e") }}</p>
                     <i></i>
                  </figcaption>
                  <span>{{ $t("PC.Solution.kmbou7") }}</span>
               </figure>
            </article>
            <img src="../../assets/zh/images/fangan_img3.jpg" alt="" />
         </div>
      </figure>
      <FooterC></FooterC>
   </section>
</section>
</template>

<script>
import FooterC from "./components/FooterC";
export default {
   data() {
      return {
         banner: require('../../assets/zh/images/fangan_banner@2x.jpg'),
         banner_en: require('../../assets/en/images/fangan_banner@2x.jpg'),
      }
   },
   components: {
      FooterC
   }
};
</script>

<style lang="scss" scoped>
.solution {
   margin-top: 70Px;
   background-color: #f1f2f6;
   word-break: break-word !important;

   .banner {
      width: 100%;
      height: 440Px;
      position: relative;
      overflow: hidden;

      img {
         display: block;
         position: absolute;
         left: 50%;
         transform: translate(-50%, 0);
         width: 1920Px;
         height: 440Px;
         margin: 0 auto;
      }
   }

   .main_contain {
      background-color: #fff;
      position: absolute;
      top: 510Px;
      width: 100%;

      figure.fg1 {
         margin: 0 auto;
         width: 960Px;
         text-align: center;

         &>p {
            background-color: #fff;
            margin: 72Px 0;
            height: 290Px;
            font-size: 24Px;
            font-weight: 500;
            color: #333;
            line-height: 58Px;
         }
      }

      figure.fg2 {
         width: 100%;
         height: auto;
         background-color: #f5f6f9;
         overflow: hidden;

         &>div {
            margin: 70Px auto;
            width: 960Px;
            height: auto;
            background: #ffffff;
            border-radius: 4Px;
            box-sizing: border-box;
            padding: 50Px 58Px;
            display: flex;
            justify-content: space-between;

            article {
               overflow: hidden;

               figure {
                  figcaption {
                     p {
                        height: 45Px;
                        font-size: 32Px;
                        color: #333300;
                        line-height: 45Px;
                        margin-top: 11Px;
                     }

                     i {
                        width: 43Px;
                        height: 4Px;
                        background: #003299;
                     }
                  }

                  span {
                     margin-top: 28Px;
                     display: block;
                     width: 454Px;
                     font-size: 16Px;
                     font-family:   PingFangSC-Regular, PingFang SC;
                     color: #666660;
                     line-height: 28Px;
                  }
               }
            }
         }
      }
   }
}
</style>
